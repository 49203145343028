import { render, staticRenderFns } from "./SATMath.vue?vue&type=template&id=fe631ad6&scoped=true"
import script from "./SATMath.vue?vue&type=script&lang=js"
export * from "./SATMath.vue?vue&type=script&lang=js"
import style0 from "./SATMath.vue?vue&type=style&index=0&id=fe631ad6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe631ad6",
  null
  
)

export default component.exports