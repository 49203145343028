<template>
  <div class="m-5 p-3">
    <div>
      <el-row>
        <el-col :md="15" class="pr-4">
          <div>
            <h2>{{ $t("SATMath.title") }}</h2>
            <br />
          </div>
          <h4 style="font-weight:bold;">{{ $t("SATMath.schedule") }}</h4>
          <ul>
            <li>{{ $t("SATMath.s1") }}</li>
            <li>{{ $t("SATMath.s2") }}</li>
            <li>{{ $t("SATMath.s3") }}</li>
            <li>{{ $t("SATMath.s4") }}</li>
            <li>{{ $t("SATMath.s5") }}</li>
            <li>{{ $t("SATMath.s6") }}</li>
          </ul>
        </el-col>
        <el-col :md="9" class="img">
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/math2c.jpg"
          />
        </el-col>
      </el-row>
    </div>
    <div class="mt-4">
      <h4 style="font-weight:bold;">{{ $t("SATMath.tuition") }}</h4>
      <ul>
        <li v-html="$t('SATMath.t1')"></li>
      </ul>
    </div>
    <div class="mt-4">
      <h4 style="font-weight:bold;">{{ $t("SATMath.materials") }}</h4>
      <ul>
        <li v-html="$t('SATMath.m1')"></li>
        <li v-html="$t('SATMath.m2')"></li>
        <li v-html="$t('SATMath.m3')"></li>
        <li v-html="$t('SATMath.m4')"></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.img {
  padding-left: 20px;
}

@media (max-width: 992px) {
  .img {
    padding-top: 20px;
    padding-left: 20px;
  }
}
</style>
